import { gql } from "@apollo/client";

import MeetingViewMeetingNewNodeFragment from "@apps/meeting-new/graphql/meeting-view-meeting-node-fragment";
import { TopicDataFragment } from "@graphql/fragments";

export const TopicSidebarTopicNode = gql`
  ${TopicDataFragment}
  fragment TopicSidebarTopicNode on TopicNode {
    ...TopicDataFragment
    discussionNotes
    includesIndividualNotes
    includesIndividualNotesForCurrentUser
    includesSharedNotes
    individualNotes {
      edges {
        node {
          id
          creator {
            id
          }
          notes
          enabled
          hidden
        }
      }
    }
    meeting {
      id
      title
      startDatetime
      websocketToken
      organization {
        id
      }
      meetingGroup {
        id
        isFormalOneonone
      }
      participants {
        edges {
          node {
            id
            status
            user {
              id
              name
              firstName
              lastName
              avatar
            }
          }
        }
      }
    }
  }
`;

export default gql`
  ${TopicSidebarTopicNode}
  ${MeetingViewMeetingNewNodeFragment}
  query getTopicSidebar($topicId: Int!) {
    topic(topicId: $topicId) {
      ...TopicSidebarTopicNode
      discussionNotes
      includesIndividualNotes
      includesIndividualNotesForCurrentUser
      includesSharedNotes
      individualNotes {
        edges {
          node {
            id
            creator {
              id
            }
            notes
            enabled
            hidden
          }
        }
      }
      meeting {
        ...MeetingViewMeetingNodeNewPageFragment
        id
        title
        startDatetime
        websocketToken
        organization {
          id
        }
        meetingGroup {
          id
          isFormalOneonone
        }
        participants {
          edges {
            node {
              id
              status
              user {
                id
                name
                firstName
                lastName
                avatar
              }
            }
          }
        }
      }
    }
  }
`;
