import { useQuery } from "@apollo/client";
import { useRef } from "react";
import {
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  GetTopicSidebarQuery,
  GetTopicSidebarQueryVariables,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import Comments from "@apps/comments/topic-comments";
import NewIndividualNotesContainer from "@apps/meeting-new/components/individual-notes-container";
import { useLink } from "@components/link/link";
import Sidebar from "@components/sidebar/sidebar";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getUrl } from "@helpers/helpers";

import Actions from "./components/actions";
import AllTime from "./components/all-time";
import Assignee from "./components/assignee";
import DiscussionNotes from "./components/discussion-notes";
import TopicSidebarError from "./components/error";
import Header from "./components/header";
import TopicSidebarLoading from "./components/loading";
import TopicSidebarNotFound from "./components/not-found";
import RelatedMeetings from "./components/related-meetings";
import getTopicQuery from "./graphql/get-topic-sidebar-query";

const TopicSidebar = ({ isInSidebar = true }) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const { path } = useRouteMatch();
  const { topicId, meetingId, meetingGroupId } = useParams<{
    topicId: string;
    meetingId: string;
    meetingGroupId: string;
  }>();
  const link = useLink();
  const location = useLocation<TFLocationState>();
  const { loading, error, data } = useQuery<
    GetTopicSidebarQuery,
    GetTopicSidebarQueryVariables
  >(getTopicQuery, {
    variables: {
      topicId: parseInt(topicId),
    },
    errorPolicy: "ignore",
    onError: onNotificationErrorHandler(),
  });

  const handleCloseSidebar = () => {
    closeSidebar({ redirect: false });
  };
  const handleDeleteTopic = () => {
    closeSidebar({ redirect: true });
  };
  const closeSidebar = ({ redirect = true }) => {
    if (location.state?.background) {
      if (redirect) {
        return link.redirect(location.state.background);
      }
      return link.replace(location.state.background);
    }
    if (meetingGroupId) {
      const url = getUrl({ meetingGroupId, meetingId });
      return link.redirect(url);
    }
    link.redirect("/");
  };

  const topic = data?.topic;
  const meeting = topic?.meeting;
  const content =
    !data && loading ? (
      <TopicSidebarLoading
        focusRef={closeButtonRef}
        onClose={handleCloseSidebar}
      />
    ) : error ? (
      <TopicSidebarError
        focusRef={closeButtonRef}
        error={error}
        onClose={handleCloseSidebar}
      />
    ) : topic && meeting ? (
      <div className="-mt-6" aria-label="Sidebar topic">
        <div className="px-4 sm:px-6 py-3 bg-white">
          <Actions
            topic={topic}
            onClose={handleCloseSidebar}
            onDelete={handleDeleteTopic}
            loading={loading}
            isInSidebar={isInSidebar}
            focusRef={closeButtonRef}
          />
        </div>
        <div className="bg-white flex gap-2 px-4 sm:px-6 pb-4">
          <RelatedMeetings topic={topic} />
        </div>
        <div className="px-4 sm:px-6 bg-white pb-4">
          <Header topic={topic} />
        </div>
        <div className="px-4 sm:px-6 py-3 bg-white">
          <Assignee
            topic={topic}
            assignableUsers={
              topic?.meeting?.participants?.edges
                ? topic?.meeting?.participants?.edges.map(
                    (edge: any) => edge.node.user
                  )
                : []
            }
          />
        </div>

        <Switch>
          <Route exact path={`${path}/all`}>
            {topic.meeting.meetingGroup && (
              <AllTime
                topic={topic}
                meetingGroup={topic.meeting.meetingGroup}
              />
            )}
          </Route>
          <Route path={[`${path}/comment/:commentId`, path]}>
            <div>
              <div className="border-t border-b bg-white">
                <DiscussionNotes
                  key={topic.id} // important to make sure discussion notes are refreshed when topic changes
                  topic={topic}
                  meeting={meeting}
                />
                <div className="p-4 pl-8 flex flex-col gap-4 empty:hidden">
                  <NewIndividualNotesContainer
                    topic={topic}
                    meeting={meeting}
                    meetingGroup={meeting.meetingGroup}
                  />
                </div>
              </div>

              <div className="p-4 sm:p-6">
                <Comments
                  topic={topic}
                  meetingId={meetingId}
                  meetingGroupId={meetingGroupId}
                />
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    ) : (
      <TopicSidebarNotFound onClose={handleCloseSidebar} />
    );

  return isInSidebar ? (
    <Sidebar
      show
      onClose={handleCloseSidebar}
      className="bg-gray-50"
      focusRef={closeButtonRef}
    >
      {content}
    </Sidebar>
  ) : (
    content
  );
};

export default TopicSidebar;
