import { useReactiveVar } from "@apollo/client";
import { HocuspocusProvider } from "@hocuspocus/provider";
import DragHandle from "@tiptap-pro/extension-drag-handle-react";
import { Editor as CoreEditor, Editor } from "@tiptap/core";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { EditorContent, EditorOptions, useEditor } from "@tiptap/react";
import { delay } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MdOutlineDragIndicator } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { ArtifactType } from "types/graphql-schema";
import { IndexeddbPersistence } from "y-indexeddb";
import * as Y from "yjs";

import {
  currentTiptapJWTVar,
  currentUserVar,
  editorVersionVar,
} from "@cache/cache";
import { useLink } from "@components/link/link";
import { classNames } from "@helpers/css";

import BubbleMenu from "./extensions/bubble-menu";
import CommentPopover from "./extensions/comment/comment-popover";
import FixedMenu from "./extensions/fixed-menu.jsx";
import { cursorColors, getExtensions, isEmptyValue } from "./helpers";
import { allSuggestion } from "./mentions/all-suggestions";
import WebsocketStatus from "./websocket-status";
import WYSIWYGOverlayDefaultNotes from "./wysiwyg-overlay-default-notes";

const WYSIWYG = ({
  value,
  overlayValue,
  editable,
  className,
  placeholder = "Type '/' to format the text...",
  emptyPlaceholder = null,
  showPlaceholderOnlyWhenEditable = true,
  enableComment = false,
  showPlusButton = false,
  showFixedMenu = false,
  isInSidebar = false,
  editorProps = {},
  fixedMenuShowFullScreen = false,
  uploadVariable,
  websocketConfig,
  mentionsConfig,
  extraContext,
  onUpdateContent,
  onFocus,
  onBlur,
}: {
  className?: string;
  enableComment: boolean;
  editable: boolean;
  showPlusButton?: boolean;
  showFixedMenu?: boolean;
  fixedMenuShowFullScreen?: boolean;
  isInSidebar?: boolean;
  placeholder?: string;
  emptyPlaceholder?: string | null;
  showPlaceholderOnlyWhenEditable?: boolean;
  uploadVariable: any;
  value: any;
  overlayValue?: any;
  editorProps?: any;
  extraContext: {
    topicId?: number;
    relatedTopicId?: number;
    meetingId?: number;
    meetingDate?: string;
    meetingGroupId?: number;
    organizationId?: number;
    relatedArtifactId?: number;
    relatedArtifactType?: ArtifactType;
  };
  websocketConfig: {
    documentId: string;
    socketId: string;
    websocketToken: string;
    onWebsocketSynced?: Function;
  };
  mentionsConfig: {
    meetingGroupId?: number;
    meetingId?: number;
    artifactId?: number;
  };
  onFocus?: EditorOptions["onFocus"];
  onBlur?: EditorOptions["onBlur"];
  onUpdateContent?: (evt: { editor: CoreEditor }) => void;
}) => {
  const link = useLink();
  const location = useLocation();
  const currentUser = currentUserVar();
  const editorVersion = editorVersionVar();
  const [showDefaultNotesOverlay, setShowDefaultNotesOverlay] = useState(
    !isEmptyValue(overlayValue) && isEmptyValue(value)
  );
  const [displayedCommentUuid, setDisplayedCommentUuid] = useState<
    string | null
  >(null);
  const { id, name, avatar } = currentUser;

  // Handling realtime
  const isRealtimeEditorSchemaUpToDate =
    editorVersion.oldVersion === editorVersion.newVersion;
  const ydoc = useMemo(() => {
    const newYdoc = websocketConfig.socketId ? new Y.Doc() : undefined;
    if (newYdoc) {
      new IndexeddbPersistence(websocketConfig.socketId, newYdoc);
    }
    return newYdoc;
  }, [websocketConfig.socketId]);
  const providerWebsocket = useMemo(() => {
    if (!websocketConfig.socketId || window.Cypress) return null;
    const provider = new HocuspocusProvider({
      url: import.meta.env.VITE_NOTE_CRDT_WEBSOCKET,
      name: websocketConfig.socketId,
      document: ydoc,
      token: websocketConfig.websocketToken,
      quiet: false,
    });
    if (websocketConfig.onWebsocketSynced) {
      provider.on("synced", websocketConfig.onWebsocketSynced);
    }
    return provider;
  }, [websocketConfig.socketId, websocketConfig.websocketToken]);

  // need to refactor this at some point
  const atMentionSuggestions = allSuggestion(mentionsConfig);
  const commentAtMentionSuggestions = allSuggestion({
    ...mentionsConfig,
    hideSearchModal: true,
    onlyUsers: true,
  });

  const context = {
    ...extraContext,
    currentUser: { id, name, avatar },
  };
  const tiptapAiJwt = useReactiveVar(currentTiptapJWTVar);
  const extensions = getExtensions({
    context,
    tiptapAiJwt: tiptapAiJwt,
    paidFeatures: currentUser.paidFeatures,
    placeholder,
    showPlaceholderOnlyWhenEditable,
    uploadVariable,
    emptyPlaceholder,
    history: false,
    atMentionSuggestions,
    onClickCommentUuid: setDisplayedCommentUuid,
  });

  const realtimeExtensions = extensions.concat([
    isRealtimeEditorSchemaUpToDate &&
      Collaboration.configure({
        document: ydoc,
        field: websocketConfig.documentId,
      }),
    providerWebsocket &&
      isRealtimeEditorSchemaUpToDate &&
      CollaborationCursor.configure({
        provider: providerWebsocket,
        user: {
          name: `${currentUser.firstName} ${currentUser.lastName[0]}`,
          color: cursorColors[currentUser.id % cursorColors.length],
        },
      }),
  ]);

  const setValueIfEditorIsEmpty = useCallback(
    (editor: CoreEditor, value: any) => {
      // if editor is synced with websocket but has no content and
      // we have some data saved in topic.discussionNotes then set content
      delay(() => {
        // wait a little to give a chance to websocket to load data
        if (
          isRealtimeEditorSchemaUpToDate &&
          editor.isEmpty &&
          !isEmptyValue(value)
        ) {
          editor.chain().setContent(value).run();
        }
      }, 1000);
    },
    [isRealtimeEditorSchemaUpToDate]
  );

  const handleTransaction = useCallback(
    ({ editor }: { editor: Editor }) => {
      if (showDefaultNotesOverlay && editor.getText()) {
        setShowDefaultNotesOverlay(false);
      }
    },
    [showDefaultNotesOverlay]
  );

  const handleCreate = useCallback(
    ({ editor }: { editor: Editor }) => {
      if (providerWebsocket) {
        // if the websocket is not yet synced, wait for that to happen and then
        // see if there is content in the database that should be seeded into
        // the editor
        if (!providerWebsocket.isSynced) {
          providerWebsocket?.on("synced", () => {
            setValueIfEditorIsEmpty(editor, value);
          });
        } else {
          // or, if the websocket is _already_ synced, for example if a new
          // topic was just added to a meeting that already has topics, can
          // just check immediately whether database content needs to be added.
          // this scenario won't fire the websocket `sync` event that is handled
          // above, because a single websocket can sync multiple editor instances
          // (i.e. multiple topics)
          setValueIfEditorIsEmpty(editor, value);
        }
      }
    },
    [providerWebsocket, setValueIfEditorIsEmpty, value]
  );

  const editor = useEditor(
    {
      autofocus: false,
      editable: editable && isRealtimeEditorSchemaUpToDate,
      // when creating/updating extensions, never rename extension.name
      // otherwise previous discussion notes won't be able to
      // render the content of that extension.
      extensions: realtimeExtensions,
      editorProps: {
        attributes: {
          class: classNames(
            "prose max-w-full pl-7 focus:outline-none break-words relative js-topic-discussion-notes-input",
            className
          ),
          "data-testid": "wysiwyg",
        },
        ...context,
        ...editorProps,
      },
      // when WebRTC enable, it seems we should not use content
      // https://github.com/ueberdosis/tiptap/discussions/2193
      content: isRealtimeEditorSchemaUpToDate ? null : value,
      onTransaction: handleTransaction,
      onCreate: handleCreate,
      // weirdly we need to do this otherwise it generates an error:
      // Cannot read properties of null (reading 'apply')
      // when onFocus or onBlur is undefined
      ...(onFocus ? { onFocus } : {}),
      ...(onBlur ? { onBlur } : {}),
    },
    [isRealtimeEditorSchemaUpToDate, editable, tiptapAiJwt] // force reload of editor when it becomes outof date and need to be disabled
  );

  const handleSetDefaultNotes = () => {
    setShowDefaultNotesOverlay(false);
    if (editor) editor.chain().setContent(overlayValue).focus().run();
  };

  useEffect(() => {
    if (onUpdateContent) {
      editor?.on("update", onUpdateContent);
    }
    return function cleanup() {
      if (onUpdateContent) {
        editor?.off("update", onUpdateContent);
      }
    };
  }, [onUpdateContent, editor]);

  // clean up websocket provider
  useEffect(() => {
    return function cleanup() {
      providerWebsocket?.destroy();
      ydoc?.destroy();
    };
  }, []);

  // If there is a comment id in the url, we try to find it in editor
  // and display the popover and remove `comment` from the url query string
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const commentId = searchParams.get("inlineComment");
    if (isInSidebar && editor && commentId) {
      const selector = `[data-comment='${commentId}']`;
      delay(() => {
        const commentEl = editor?.view.dom.querySelector(selector);
        if (commentEl) {
          setDisplayedCommentUuid(commentId);
          commentEl.scrollIntoView();
        }
        const params = new URLSearchParams(location.search);
        params.delete("inlineComment");
        link.replace(`${location.pathname}?${params.toString()}`);
      }, 1000);
    }
  }, [editor, value]);

  return (
    <>
      {showPlusButton && editable && editor && (
        <DragHandle editor={editor}>
          <span className="bg-gray-100 hover:bg-gray-200 py-0.5 flex items-center justify-center text-gray-400 rounded cursor-grab">
            <MdOutlineDragIndicator className="w-4 h-4" />
          </span>
        </DragHandle>
      )}
      {editor && !editor.isDestroyed && (
        <BubbleMenu
          editor={editor}
          meetingId={context.meetingId}
          topicId={extraContext.relatedTopicId}
          organizationId={context.organizationId}
          enableComment={enableComment}
          onCreateCommentUuid={setDisplayedCommentUuid}
        />
      )}
      {showFixedMenu && editor && !editor.isDestroyed && (
        <FixedMenu
          editor={editor}
          fixedMenuShowFullScreen={fixedMenuShowFullScreen}
          fullScreenArtifactId={context.relatedArtifactId}
          fullScreenArtifactType={context.relatedArtifactType}
        />
      )}
      {displayedCommentUuid && editor && (
        <CommentPopover
          editor={editor}
          uuid={displayedCommentUuid}
          topicId={context.topicId}
          artifactId={context.relatedArtifactId}
          atMentionSuggestions={commentAtMentionSuggestions}
          uploadVariable={uploadVariable}
          onClearUuid={() => setDisplayedCommentUuid(null)}
        />
      )}

      {showDefaultNotesOverlay && (
        <WYSIWYGOverlayDefaultNotes
          disabled={!editable}
          onSetDefaultNotes={handleSetDefaultNotes}
          overlayValue={overlayValue}
          extensions={extensions}
          extraContext={extraContext}
          className={className}
          editorProps={editorProps}
        />
      )}
      <div className={classNames(showDefaultNotesOverlay ? "hidden" : "block")}>
        <EditorContent editor={editor} />
      </div>

      {editor && !editor.isDestroyed && (
        <WebsocketStatus
          isRealtimeEditorSchemaUpToDate={isRealtimeEditorSchemaUpToDate}
          isFocused={editor.isFocused}
          status={providerWebsocket?.status}
        />
      )}
    </>
  );
};

export default WYSIWYG;
