import Ai from "@tiptap-pro/extension-ai";
import { Extension } from "@tiptap/core";
import { Editor as CoreEditor } from "@tiptap/core";
import BulletList from "@tiptap/extension-bullet-list";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import Highlight from "@tiptap/extension-highlight";
import ListKeymap from "@tiptap/extension-list-keymap";
import OrderedList from "@tiptap/extension-ordered-list";
import Placeholder from "@tiptap/extension-placeholder";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import { ReactNodeViewRenderer } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import json5 from "json5";
import { isArray, isEmpty } from "lodash";
import isString from "lodash/isString";
import { lowlight } from "lowlight/lib/common";
import { GapCursor } from "prosemirror-gapcursor";
import { Plugin, PluginKey } from "prosemirror-state";
import { PaidFeaturesNode } from "types/graphql-schema";

import { errorNotificationVar } from "@cache/cache";
import DeprecatedKpiExtension from "@components/wysiwyg/extensions/artifacts/kpi-embed/deprecated-kpi-artifact";
import DeprecatedGithubExtension from "@components/wysiwyg/extensions/deprecated-github";
import UrlPreview from "@components/wysiwyg/extensions/url-preview";

import ActionItem from "./extensions/artifacts/action-item-extension";
import AsanaExtension from "./extensions/artifacts/asana/asana-extension";
import Decision from "./extensions/artifacts/decision-extension";
import Document from "./extensions/artifacts/document-extension";
import Feedback from "./extensions/artifacts/feedback-extension";
import Goal from "./extensions/artifacts/goal-extension";
import KpiEmbedExtension from "./extensions/artifacts/kpi-embed/kpi-embed";
import KpiGroupEmbedExtension from "./extensions/artifacts/kpi-embed/kpi-group-embed";
import DeprecatedRatingExtension from "./extensions/artifacts/rating/rating-artifact";
import Recognition from "./extensions/artifacts/recognition-extension";
import ButtonExtension from "./extensions/button";
import CodeBlockComponent from "./extensions/code-bock";
import Commands, { // CommandsButton,
  getSuggestionItems,
  renderItems,
} from "./extensions/commands.jsx";
import { Comment } from "./extensions/comment/comment-extension.jsx";
import Iframe from "./extensions/embeds/iframe.jsx";
import ExplorerExtension from "./extensions/explorer-extension";
import CustomImage, { asyncUploadImage } from "./extensions/image";
import CustomLink from "./extensions/link";
import CustomListItem from "./extensions/list-item";
import MeetingEmbedExtension from "./extensions/meeting-embed";
import { CustomMention } from "./extensions/mention";
import Timer from "./extensions/timer";

export const toggleLink = (editor: CoreEditor) => {
  let selectedText = "";
  const { from, to, empty } = editor.state.selection;
  if (!empty) {
    selectedText = editor.state.doc.textBetween(from, to, " ");
  }
  if (editor.isActive("link")) {
    return editor.chain().focus().unsetLink().run();
  } else if (selectedText) {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);
    // cancelled
    if (url === null) {
      return false;
    }
    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      return false;
    }
    // update link
    return editor
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url })
      .run();
  }
  return false;
};

export const isEmptyValue = (value: any) => {
  const isEmptyTiptapJSON = (jsonStr: any) => {
    if (
      jsonStr.type === "doc" &&
      jsonStr.content.every((c: any) => {
        return (
          c.type === "paragraph" &&
          (!c.content ||
            (!isArray(c.content) && c.content.trim() === "") ||
            (isArray(c.content) &&
              c.content.every(
                (t: any) => t.type === "text" && t.text.trim() === ""
              )))
        );
      })
    ) {
      return true;
    }
    return false;
  };
  if (!value) {
    return true;
  }
  if (isEmpty(value)) {
    return true;
  }
  if (isString(value)) {
    if (value === "" || value === "{}") {
      return true;
    }
    try {
      return isEmptyTiptapJSON(json5.parse(value));
    } catch (e) {
      return false;
    }
  }
  return isEmptyTiptapJSON(value);
};

export const cursorColors = [
  "#f783ac",
  "#f87171",
  "#f97316",
  "#f59e0b",
  "#84cc16",
  "#10b981",
  "#06b6d4",
  "#60a5fa",
  "#a78bfa",
  "#e879f9",
  "#fb7185",
];

export const getAiExtension = (
  tiptapAiJwt: string,
  config?: {
    onLoading?: () => void;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
  }
) => {
  const aiConfig = {
    appId: "78dwgxk2",
    token: tiptapAiJwt || undefined,
    autocompletion: false,
    onLoading: config?.onLoading,
    onSuccess: config?.onSuccess,
    onError: (error: Error) => {
      if (error.message.toLocaleLowerCase().includes("invalid token")) {
        errorNotificationVar({
          title: "Outdated Assist AI credentials",
          description:
            "Please refresh your browser to update the Assist AI credentials.",
        });
      } else {
        errorNotificationVar({
          title: "Assist AI",
          description:
            "An error occurred with Assist AI. Please refresh your browser.",
        });
      }
      if (config?.onError) config.onError(error);
    },
  };
  return Ai.configure(aiConfig);
};

export const getExtensions = ({
  context,
  paidFeatures,
  placeholder,
  emptyPlaceholder,
  uploadVariable,
  atMentionSuggestions,
  onClickCommentUuid,
  showPlaceholderOnlyWhenEditable = true,
  tiptapAiJwt,
  history,
}: {
  context: any;
  paidFeatures: PaidFeaturesNode;
  placeholder: string;
  emptyPlaceholder: string | any;
  uploadVariable: {
    artifactId?: number;
    topicId?: number;
  };
  atMentionSuggestions: any;
  onClickCommentUuid?: (uuid: string) => void;
  showPlaceholderOnlyWhenEditable?: boolean;
  history?: false;
  tiptapAiJwt?: string;
}) => {
  const extensions = [
    waffle.flag_is_active("tiptap-ai") &&
      tiptapAiJwt &&
      getAiExtension(tiptapAiJwt),

    Decision.configure({
      ...context,
      permission: paidFeatures.canCreateDecisions,
    }),
    Feedback.configure({
      ...context,
      permission: true,
    }),
    Recognition.configure({
      ...context,
      permission: true,
    }),
    ActionItem.configure({
      ...context,
      permission: true,
    }),
    Document.configure({
      ...context,
      permission: true,
    }),
    Goal.configure({
      ...context,
      permission: paidFeatures.canCreateGoals,
    }),
    KpiEmbedExtension.configure(context),
    KpiGroupEmbedExtension.configure(context),
    MeetingEmbedExtension.configure(context),
    AsanaExtension.configure(context),
    GapCursor,
    UrlPreview,
    Iframe,
    ExplorerExtension,
    CustomLink.configure({
      openOnClick: true,
      autolink: true,
      HTMLAttributes: {
        class: "js-wysiwyg-link",
      },
    }),
    StarterKit.configure({
      codeBlock: false,
      bulletList: false,
      orderedList: false,
      listItem: false,
      history: history,
    }),
    Timer,
    ButtonExtension,
    Underline,
    CustomListItem,
    BulletList.configure({ itemTypeName: "listItem" }),
    OrderedList.configure({ itemTypeName: "listItem" }),
    ListKeymap,
    Placeholder.configure({
      placeholder: ({ editor: editorPlaceholder }) => {
        if (editorPlaceholder.isEmpty && emptyPlaceholder) {
          return emptyPlaceholder;
        }
        return placeholder;
      },
      showOnlyWhenEditable: showPlaceholderOnlyWhenEditable,
      showOnlyCurrent: true,
      includeChildren: false,
    }),
    Table.configure({ resizable: true }),
    TableRow,
    TableHeader,
    TableCell,
    Commands.configure({
      suggestion: {
        items: getSuggestionItems({
          permissionDecision: !!paidFeatures.canCreateDecisions,
          permissionGoal: !!paidFeatures.canCreateGoals,
        }),
        render: renderItems,
      },
    }),
    !!onClickCommentUuid && Comment.configure({ onClickCommentUuid }),
    Typography,
    CustomImage(asyncUploadImage(uploadVariable)),
    CodeBlockLowlight.extend({
      addNodeView() {
        return ReactNodeViewRenderer(CodeBlockComponent);
      },
    }).configure({ lowlight }),
    Extension.create({
      addKeyboardShortcuts() {
        return {
          "Cmd-Enter"() {
            return true;
          },
          "Ctrl-Enter"() {
            return true;
          },
        };
      },
    }),
    atMentionSuggestions &&
      CustomMention.configure({
        HTMLAttributes: { class: "mention" },
        suggestion: atMentionSuggestions,
      }),
    Highlight.configure({ multicolor: true }),

    // DEPRECATED: do not remove otherwise it'll break the schema and will empty notes
    DeprecatedRatingExtension.configure(context),
    DeprecatedGithubExtension,
    DeprecatedKpiExtension.configure(context),
  ];
  return extensions;
};

export const getPluginClickOn = (name: string) =>
  new Plugin({
    key: new PluginKey(`${name}-click-on`),
    props: {
      // https://github.com/ueberdosis/tiptap/issues/1053
      // https://prosemirror.net/docs/ref/#view.EditorProps.handleClickOn
      // Helps control click behaviour
      handleClickOn: (view, pos, node, nodePos, event, direct) => {
        if (node.type.name === name) {
          return true;
        }
        return false;
      },
    },
  });

export const getPluginHandlePaste = (
  self: any,
  cb: (text: string, view: any) => boolean
) =>
  new Plugin({
    key: new PluginKey(`${self.name}-paste`),
    props: {
      handlePaste: (view, event) => {
        if (!event.clipboardData) {
          return false;
        }
        // don’t create a new code block within code blocks
        if (self.editor.isActive(self.type.name)) {
          return false;
        }
        const text = event.clipboardData.getData("text/plain");
        return cb(text, view);
      },
    },
  });
