import moment from "moment";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ArtifactType,
  AttendeeRole,
  AttendeeStatus,
  GoalArtifactNode,
  GoalState,
  RecognitionArtifactNode,
} from "types/graphql-schema";
import { BasicUser, TFLocationState } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import { getExplorerFiltersUrl } from "@apps/explorer/helpers";
import GoalProgressPillWithPopover from "@apps/goal-progress-pill-with-popover/goal-progress-pill-with-popover";
import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import AppLink from "@components/link/link";
import { TableBodyCell, TableBodyRow } from "@components/table/table";
import TimeAgoCustom from "@components/time-ago/time-ago";
import {
  assertEdgesNonNull,
  assertNonNull,
  getUrl,
  removeCurrentYear,
  toWithBackground,
} from "@helpers/helpers";

import getDashboardRecognitionsQuery from "../graphql/get-dashboard-recognitions-query";
import getPeopleTableQuery from "../graphql/get-people-table-query";
import { PeopleTableRowUser, peopleTableGoalMax } from "./people-table";

const DashboardPeopleTableRow = ({
  selectedUser,
  user,
  hideOneOnOneCTA = false,
}: {
  selectedUser: BasicUser;
  user: PeopleTableRowUser;
  hideOneOnOneCTA?: boolean;
}) => {
  const location = useLocation<TFLocationState>();
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();
  const [
    isShowingCreateRecognitionDialog,
    setIsShowingCreateRecognitionDialog,
  ] = useState(false);
  const [isShowingCreateOneononeDialog, setIsShowingCreateOneononeDialog] =
    useState(false);

  const goals = (
    user?.goals ? assertEdgesNonNull(user.goals) : []
  ) as GoalArtifactNode[];
  const goalCount = user?.goals?.totalCount || goals.length;
  const maxGoalCountDisplayed =
    goalCount > peopleTableGoalMax ? peopleTableGoalMax - 1 : goalCount;
  const recognitions = (
    user?.recognitions ? assertEdgesNonNull(user.recognitions) : []
  ) as RecognitionArtifactNode[];
  const lastRecognition = recognitions[0];
  const nextOneonone = user.meetings[0];

  const handleClickGiveRecognition = () => {
    setIsShowingCreateRecognitionDialog(true);
  };
  const handleCloseGiveRecognition = () => {
    setIsShowingCreateRecognitionDialog(false);
  };
  const handleClickScheduleMeeting = () => {
    setIsShowingCreateOneononeDialog(true);
  };
  const handleCloseScheduleMeeting = () => {
    setIsShowingCreateOneononeDialog(false);
  };

  // RENDER
  return (
    <TableBodyRow key={user.id}>
      <TableBodyCell className="text-gray-700">
        {isShowingCreateRecognitionDialog && (
          <ArtifactCreationDialog
            formOptions={{
              artifactType: ArtifactType.Recognition,
              recognitionRecipients: [user],
            }}
            onClose={handleCloseGiveRecognition}
            refetchQueries={[
              getPeopleTableQuery,
              getDashboardRecognitionsQuery,
            ]}
          />
        )}
        {isShowingCreateOneononeDialog && (
          <MeetingDialog
            formOptions={{
              title: `${label("oneonone")}: ${user.firstName} <> ${
                selectedUser.firstName
              }`,
              isFormalOneonone: true,
              attendees: [
                {
                  ...selectedUser,
                  email: assertNonNull(selectedUser.email),
                  role: AttendeeRole.Required,
                  participantStatus: AttendeeStatus.NotResponded,
                },
                {
                  ...user,
                  role: AttendeeRole.Required,
                  participantStatus: AttendeeStatus.NotResponded,
                },
              ],
              facilitatorId: selectedUser.id,
            }}
            onClose={handleCloseScheduleMeeting}
            refetchQueries={[getPeopleTableQuery]}
          />
        )}
        <div className="flex items-center gap-2 flex-auto min-w-0">
          <AppLink to={getUrl({ userId: user.id })}>
            <Avatar user={user} size="5" />
          </AppLink>
          <AppLink
            to={getUrl({ userId: user.id })}
            className="text-gray-900 text-sm hover:underline truncate"
          >
            {user.name}
          </AppLink>
        </div>
      </TableBodyCell>
      {currentOrganization.featureFlags.goals && (
        <TableBodyCell>
          <div className="flex flex-none items-center gap-0.5">
            {goals.length === 0 && <span className="text-gray-400">None</span>}
            {goals.slice(0, maxGoalCountDisplayed).map((goal) => (
              <GoalProgressPillWithPopover
                key={goal.id}
                artifact={goal}
                showOnlyStatusPill
              />
            ))}
            {goalCount > maxGoalCountDisplayed && (
              <AppLink
                to={getExplorerFiltersUrl({
                  type: ArtifactType.Goal,
                  goalOwners: user.id,
                  goalState: [GoalState.Open],
                })}
                className="text-xs ml-1 hover:underline"
              >
                +{goalCount - maxGoalCountDisplayed}
              </AppLink>
            )}
          </div>
        </TableBodyCell>
      )}
      <TableBodyCell>
        {nextOneonone ? (
          <AppLink
            to={getUrl({
              meetingGroupId: nextOneonone.meetingGroupId,
              meetingId: nextOneonone.id,
            })}
            className="text-blue-link hover:underline"
          >
            {removeCurrentYear(
              moment(nextOneonone.startDatetime).format("lll")
            )}
          </AppLink>
        ) : hideOneOnOneCTA ? null : (
          <button
            className="text-blue-link hover:underline"
            onClick={handleClickScheduleMeeting}
          >
            Schedule {label("oneonone")}
          </button>
        )}
      </TableBodyCell>
      {currentOrganization.featureFlags.recognitions && (
        <TableBodyCell>
          <div className="hidden @xl/people-table:flex flex-none items-center gap-0.5 w-[160px] tracking-tight">
            {lastRecognition ? (
              <AppLink
                to={toWithBackground({
                  pathname: getUrl({
                    artifactId: lastRecognition.id,
                    artifactType: lastRecognition.artifactType,
                  }),
                  location,
                })}
                className="text-blue-link hover:underline"
              >
                <TimeAgoCustom
                  date={moment(lastRecognition.created).toDate()}
                />
              </AppLink>
            ) : (
              <button
                className="text-blue-link hover:underline"
                onClick={handleClickGiveRecognition}
              >
                Give a {label("recognition")}
              </button>
            )}
          </div>
        </TableBodyCell>
      )}
    </TableBodyRow>
  );
};

export default DashboardPeopleTableRow;
