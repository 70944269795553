import { useReactiveVar } from "@apollo/client";
import { EditorContent, useEditor } from "@tiptap/react";
import { useEffect } from "react";

import { currentTiptapJWTVar, currentUserVar } from "@cache/cache";
import { classNames } from "@helpers/css";

import BubbleMenu from "./extensions/bubble-menu";
import { getExtensions } from "./helpers";
import { allSuggestion } from "./mentions/all-suggestions";

const TemplateTopicWYSIWYG = ({
  value,
  editable = false,
  className,
  placeholder = "Type '/' to format the text...",
  emptyPlaceholder = null,
  editorProps = {},
  mentionsConfig,
  extraContext,
  onUpdateContent,
  uploadVariable,
}: {
  className?: string;
  editable: boolean;
  placeholder?: string;
  emptyPlaceholder?: string | null;
  uploadVariable: any;
  value: any;
  editorProps?: any;
  extraContext: {
    topicId?: number;
    relatedTopicId?: number;
    meetingId?: number;
    meetingDate?: string;
    meetingGroupId?: number;
    organizationId?: number;
    relatedArtifactId?: number;
    relatedArtifactType?: number;
  };
  mentionsConfig: {
    meetingGroupId?: number;
    meetingId?: number;
    artifactId?: number;
  };
  onUpdateContent?: () => void;
}) => {
  const currentUser = currentUserVar();
  const { id, name, avatar } = currentUser;

  // need to refactor this at some point
  const atMentionSuggestions = allSuggestion({
    artifactId: mentionsConfig.artifactId,
    meetingGroupId: mentionsConfig.meetingGroupId,
    meetingId: mentionsConfig.meetingId,
  });

  const context = {
    ...extraContext,
    currentUser: { id, name, avatar },
  };
  const tiptapAiJwt = useReactiveVar(currentTiptapJWTVar);
  const extensions = getExtensions({
    context,
    tiptapAiJwt,
    paidFeatures: currentUser.paidFeatures,
    placeholder,
    uploadVariable,
    emptyPlaceholder,
    atMentionSuggestions,
    history: undefined,
  });

  const editor = useEditor(
    {
      autofocus: false,
      editable,
      // when creating/updating extensions, never rename extension.name
      // otherwise previous discussion notes won't be able to
      // render the content of that extension.
      extensions: extensions,
      editorProps: {
        attributes: {
          class: classNames(
            "prose max-w-full pl-7 focus:outline-none break-words relative js-template-default-notes",
            className
          ),
        },
        isTemplate: true,
        ...context,
        ...editorProps,
      },
      content: value,
    },
    [tiptapAiJwt]
  );

  useEffect(() => {
    if (onUpdateContent) {
      editor?.on("update", onUpdateContent);
    }
    return function cleanup() {
      if (onUpdateContent) {
        editor?.off("update", onUpdateContent);
      }
    };
  }, [onUpdateContent, editor]);

  return (
    <>
      {editor && !editor.isDestroyed && (
        <BubbleMenu
          editor={editor}
          meetingId={context.meetingId}
          topicId={extraContext.relatedTopicId}
          organizationId={context.organizationId}
          enableComment={false}
        />
      )}
      <EditorContent editor={editor} />
    </>
  );
};

export default TemplateTopicWYSIWYG;
