import { ErrorBoundary } from "@sentry/react";
import { Editor } from "@tiptap/core";
import {
  MeetingParticipantNode,
  MeetingViewMeetingNodeNewPageFragmentFragment,
  TopicNodeNewPageFragmentFragment,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import cache, { currentUserVar } from "@cache/cache";
import Error from "@components/error/error";
import Loading from "@components/loading/loading";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import WYSIWYG from "@components/wysiwyg/wysiwyg";
import { tempCacheIdPrefix } from "@helpers/constants";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertNonNull,
  parseStringToJSON,
} from "@helpers/helpers";

const IndividualNotes = ({
  topic,
  currentUserNoteHidden,
  meeting,
  meetingGroup,
  participant,
  onWebsocketSynced,
}: {
  topic: TopicNodeNewPageFragmentFragment;
  currentUserNoteHidden: boolean;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  meetingGroup: {
    id: number;
    isFormalOneonone: boolean;
    facilitator?: BasicUser;
  };
  participant: Pick<MeetingParticipantNode, "participantEmail"> & {
    user?: { id: number; firstName: string } | null;
  };
  onWebsocketSynced: Function;
}) => {
  const currentUser = currentUserVar();
  const isTemporary = String(topic.id).includes(tempCacheIdPrefix);
  const { isFormalOneonone } = meetingGroup;

  const individualNotes = assertEdgesNonNull(topic.individualNotes) || [];
  const individualNote = individualNotes.find(
    ({ creator }) => creator.id === participant?.user?.id
  );
  const note = individualNote?.notes || "";

  const key = `editor-topic-${topic.id}-${
    currentUserNoteHidden ? "private" : "public"
  }`;
  const canEdit =
    participant.user?.id === currentUser.id && topic.canUpdate.permission;

  let overlayValue = null;
  if (isFormalOneonone && isEmptyValue(note) && topic.linkedTemplateTopic?.id) {
    const defaultNotes =
      meetingGroup.facilitator?.id === participant.user?.id
        ? topic.linkedTemplateTopic.defaultFacilitatorNotes
        : topic.linkedTemplateTopic.defaultSubjectNotes;
    if (defaultNotes) overlayValue = parseStringToJSON(defaultNotes);
  }

  const handleUpdateContent = ({ editor }: { editor: Editor }) => {
    if (canEdit && individualNote) {
      cache.modify({
        id: cache.identify(individualNote),
        fields: {
          notes() {
            return editor.getJSON();
          },
        },
      });
    }
  };

  return (
    <div className="" aria-label="Meeting topic individual notes">
      <div>
        {isTemporary ? (
          <div className="pb-4">
            <Loading size="5" />
          </div>
        ) : (
          <ErrorBoundary
            fallback={
              <Error
                title="An unexpected error occurred."
                description={
                  "The editor could not be rendered. We have been notified. Please refresh the page."
                }
              />
            }
          >
            <div id={key} key={key}>
              <WYSIWYG
                key={key}
                enableComment={false}
                value={parseStringToJSON(note)}
                className={classNames(
                  "js-individual-notes",
                  "text-base min-h-11",
                  // important to have padding so the + hover button on each line is clickable, as well as detecting when to hide the add/drag buttons when cursor leaves the editor
                  // https://github.com/Topicflow/topicflow/pull/1314
                  "-ml-[30px] pl-[46px] pt-2 pr-1",
                  "pb-8" // adding bottom padding here so the white space is clickable
                )}
                editable={canEdit}
                placeholder={
                  canEdit
                    ? "Add my notes..."
                    : `${
                        participant.user?.firstName ||
                        participant?.participantEmail ||
                        "Participant"
                      }'s notes.`
                }
                overlayValue={overlayValue}
                showPlaceholderOnlyWhenEditable={false}
                showPlusButton={canEdit}
                uploadVariable={{ topicId: topic.id }}
                websocketConfig={{
                  socketId: `meeting-${meeting?.id}-participant-${
                    participant.user?.id
                  }${currentUserNoteHidden ? "-private" : ""}`,
                  documentId: `topic-${topic.id}-participant-${
                    participant.user?.id
                  }${currentUserNoteHidden ? "-private" : ""}`,
                  websocketToken: assertNonNull(meeting?.websocketToken),
                  onWebsocketSynced: onWebsocketSynced,
                }}
                mentionsConfig={{
                  meetingGroupId: meeting?.meetingGroup?.id,
                  meetingId: meeting?.id,
                }}
                extraContext={{
                  topicId: topic.id,
                  relatedTopicId: !currentUserNoteHidden ? topic.id : undefined,
                  meetingId: meeting.id,
                  meetingGroupId: meeting.meetingGroup?.id,
                  organizationId: meeting.organization?.id,
                  meetingDate: meeting.startDatetime
                    ? meeting.startDatetime
                    : undefined,
                }}
                onUpdateContent={handleUpdateContent}
              />
            </div>
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
};

export default IndividualNotes;
