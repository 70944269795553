import { compact, uniqueId } from "lodash";
import moment from "moment";

import { getLabel } from "@apps/use-label/use-label";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";

export const meetingPaddingClassName = "px-6";

export const focusOnNextInput = (event: any) => {
  const focusableElements = Array.from(
    document.querySelectorAll<HTMLDivElement>(
      ".ProseMirror:not(.js-artifact-input):not(.js-overlay-default-notes)"
    )
  );
  const focusedIndex = focusableElements.findIndex((el) => el === event.target);
  if (focusedIndex >= 0 && focusableElements[focusedIndex + 1]) {
    focusableElements[focusedIndex + 1].focus();
  }
};

export const focusOnPreviousInput = (event: any) => {
  const focusableElements = Array.from(
    document.querySelectorAll<any>(
      ".ProseMirror:not(.js-artifact-input):not(.js-overlay-default-notes)"
    )
  );
  const focusedIndex = focusableElements.findIndex((el) => el === event.target);
  if (focusedIndex > 0) {
    const { editor } = focusableElements[focusedIndex - 1];
    editor.chain().focus("end").run();
  }
};

export const currentMeetingOption = {
  value: "current-meeting-group",
  label: "in this meeting series",
};
export const allMeetingsOption = {
  value: "all-meetings",
  label: "all meetings",
};
export const searchContextOptions = [currentMeetingOption, allMeetingsOption];

export const getOptimisticResponseWhenTogglingIndividualNotes = (
  meeting: any,
  topic: any,
  enabled: boolean
) => {
  const currentUser = currentUserVar();
  // build optimistic response
  const individualNoteOfUser = topic.individualNotes.edges.find(
    ({ node }: { node: any }) => node.creator.id === currentUser.id
  );
  const updatedIndividualNotesEdges = individualNoteOfUser
    ? topic.individualNotes.edges.map((edge: any) => {
        if (edge.node.id === individualNoteOfUser?.node.id) {
          return { ...edge, node: { ...edge.node, enabled } };
        }
        return edge;
      })
    : [
        ...topic.individualNotes.edges,
        {
          node: {
            id: uniqueId("tmp-"),
            created: moment().toISOString(),
            creator: {
              id: currentUser.id,
              __typename: currentUser.__typename,
            },
            enabled,
            hidden: false,
            notes: "",
            __typename: "IndividualNoteNode",
          },
          __typename: "IndividualNoteNodeEdge",
        },
      ];
  const updatedIndividualNotes = {
    ...topic.individualNotes,
    edges: updatedIndividualNotesEdges,
  };
  const updatedTopicNode = {
    ...topic,
    includesIndividualNotesForCurrentUser: enabled,
    individualNotes: updatedIndividualNotes,
  };
  const newTopicEdges = meeting.topics.edges.map((edge: any) => ({
    ...edge,
    node: {
      ...edge.node,
      ...(topic.id === edge.node.id ? updatedTopicNode : {}),
    },
  }));
  return {
    toggleIndividualNoteVisibility: {
      topics: {
        ...meeting.topics,
        edges: newTopicEdges,
      },
      __typename: "ToggleIndividualNoteVisibilityMutation",
    },
  };
};

export type MeetingOrTemplateVisibility = {
  allowAdminVisibility: boolean;
  allowOrgVisibility: boolean;
  allowManagementTreeVisibility: boolean;
};

export const getMeetingAllowVisibilityLabel = (
  {
    allowAdminVisibility,
    allowOrgVisibility,
    allowManagementTreeVisibility,
  }: MeetingOrTemplateVisibility,
  shortVersion = false
): string => {
  const label = getLabel();
  const orgLabel = label("organization");
  const currentOrganization = currentOrganizationVar();
  if (allowOrgVisibility)
    return shortVersion
      ? `${orgLabel} members`
      : `all ${currentOrganization.name} members`;
  const parts = shortVersion
    ? compact([
        "participants",
        allowManagementTreeVisibility && "management tree",
        allowAdminVisibility && `admins`,
      ])
    : compact([
        "meeting participants",
        allowManagementTreeVisibility && "participants' management tree",
        allowAdminVisibility && `${currentOrganization.name} admins`,
      ]);
  if (parts.length === 1) {
    return parts.join("") + " only";
  }
  const last = parts.pop();
  return parts.join(", ") + " and " + last;
};
