import { XIcon } from "@heroicons/react/outline";
import { ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import CalendarConnectNew from "@apps/account/components/calendar-connect-new";
import getSidebarDataQuery from "@apps/main/graphql/get-sidebar-data-query";
import MeetingDialog, {
  meetingDialogAction,
} from "@apps/meeting-dialog/meeting-dialog";
import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Modal from "@components/modal/modal";
import { providerUrl } from "@helpers/constants";
import { assertEdgesNonNull, getUrl } from "@helpers/helpers";

import getMeetingQuery from "../graphql/get-meeting-query";

const PublishMeetingButton = ({
  meeting,
  children,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  children: ReactNode | string;
}) => {
  const currentUser = currentUserVar();
  const [showConnectCalendarModal, setShowConnectCalendarModal] =
    useState(false);

  const calendars = assertEdgesNonNull(currentUser.syncCredentials);
  const validCalendars = calendars.filter(
    (node) => node.hasValidCalendarCredentials
  );
  const validCalendarsWithWriteAccess = validCalendars.filter(
    (node) => node.hasCalendarWriteCredentials
  );
  const validCalendarsWithoutWriteAccess = validCalendars.filter(
    (node) => !node.hasCalendarWriteCredentials
  );

  const noValidCalendars = validCalendars.length === 0;
  const noCalendarsWithWriteAccess =
    validCalendars.length > 0 && validCalendarsWithWriteAccess.length === 0;
  const disabledPublishMeetingButton =
    noValidCalendars || noCalendarsWithWriteAccess;
  const disabledPublishMeetingTooltip =
    currentUser.status === "demo"
      ? "Demo accounts cannot publish meetings"
      : noValidCalendars
      ? "Please connect your calendar."
      : noCalendarsWithWriteAccess
      ? "Please allow write access to your calendar"
      : null;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const openEditModal = queryParams.get("openEditModal") === "1";
  const [showCreateMeetingModal, setShowCreateMeetingModal] =
    useState(openEditModal);

  const handleClickPublish = () => {
    if (!disabledPublishMeetingTooltip) {
      setShowCreateMeetingModal(true);
    }
  };

  const handleHideMeetingDialog = () => {
    setShowCreateMeetingModal(false);
  };

  const handleClickConnectCalendar = () => {
    setShowConnectCalendarModal(true);
  };

  return (
    <>
      {showCreateMeetingModal && (
        <MeetingDialog
          meetingId={meeting.id}
          meetingGroupId={meeting.meetingGroup?.id}
          onClose={handleHideMeetingDialog}
          defaultAction={meetingDialogAction.publish}
          onSaved={handleHideMeetingDialog}
          refetchQueries={[getSidebarDataQuery, getMeetingQuery]}
        />
      )}
      <Modal open={showConnectCalendarModal}>
        <div
          className="p-6 flex justify-between items-start gap-8"
          aria-label="Connect calendar modal"
        >
          <div className="flex-1">
            <CalendarConnectNew
              next={getUrl({
                meetingGroupId: meeting.meetingGroup?.id,
                meetingId: meeting.id,
              })}
            />
          </div>
          <button
            onClick={() => setShowConnectCalendarModal(false)}
            className="p-1 rounded hover:bg-gray-100 text-gray-700"
            aria-label="Close modal"
          >
            <XIcon className="w-7 h-7" />
          </button>
        </div>
      </Modal>
      {!disabledPublishMeetingButton ? (
        <Button
          theme={buttonTheme.primary}
          onClick={handleClickPublish}
          disabled={disabledPublishMeetingButton}
          tooltip={disabledPublishMeetingTooltip}
          className="gap-2 items-center truncate overflow-hidden"
          small
        >
          {children}
        </Button>
      ) : validCalendars.length === 0 && currentUser.status !== "demo" ? (
        <div className="text-gray-600 text-sm w-40">
          <button
            className="text-blue-700 font-medium hover:underline"
            onClick={handleClickConnectCalendar}
          >
            Connect your calendar
          </button>{" "}
          to publish this meeting.
        </div>
      ) : (
        validCalendars.length > 0 &&
        validCalendarsWithWriteAccess.length === 0 &&
        validCalendarsWithoutWriteAccess[0]?.provider && (
          <div className="text-gray-600 text-sm w-36 sm:w-48">
            <a
              className="text-blue-700 font-medium hover:underline"
              href={`/sso/${
                providerUrl[validCalendarsWithoutWriteAccess[0].provider]
              }?request_calendar_scope=true&request_write_scope=true&next=${getUrl(
                {
                  meetingGroupId: meeting.meetingGroup?.id,
                  meetingId: meeting.id,
                }
              )}`}
            >
              Allow calendar write access
            </a>{" "}
            to publish this meeting.
          </div>
        )
      )}
    </>
  );
};

export default PublishMeetingButton;
