import { Popover } from "@headlessui/react";
import { ChevronRightIcon, SelectorIcon } from "@heroicons/react/outline";
import { truncate } from "lodash";
import moment from "moment";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import AppLink from "@components/link/link";
import {
  formatMeetingTimes,
  getUrl,
  removeCurrentYear,
} from "@helpers/helpers";

import MeetingDraftPopoverPanel from "./draft-popover-panel";
import PreviousMeetingsPopoverPanel from "./previous-meetings-popover-panel";

const MeetingBreadcrumb = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const meetingDate = meeting.startDatetime ? (
    <span>
      {meeting.draft ? "Draft, " : ""}
      <span className="inline">
        {removeCurrentYear(moment(meeting.startDatetime).format("ll"))}
      </span>
      <span className="hidden @7xl/layout-header:inline">
        , {formatMeetingTimes(meeting)}
      </span>
    </span>
  ) : meeting.draft ? (
    "Draft, unscheduled"
  ) : (
    "Unscheduled"
  );

  return (
    <div className="flex gap-2 items-center text-sm text-gray-600">
      <AppLink className="hover:underline" to="/calendar">
        Meetings
      </AppLink>
      <ChevronRightIcon className="h-3 w-3 text-gray-500" />
      <AppLink
        className="hover:underline"
        to={getUrl({
          meetingGroupId: meeting.meetingGroup?.id,
          meetingId: meeting.id,
        })}
      >
        {truncate(meeting.title || meeting.meetingGroup?.title || "", {
          length: 48,
          separator: " ",
        })}
      </AppLink>
      <ChevronRightIcon className="h-3 w-3 text-gray-500" />
      <Popover className="flex items-center relative">
        <Popover.Button className="flex items-center gap-1.5 hover:underline">
          <span>{meetingDate}</span>
          <SelectorIcon className="h-4 w-4 text-gray-500" />
        </Popover.Button>
        {meeting.draft ? (
          <MeetingDraftPopoverPanel meeting={meeting} />
        ) : (
          <PreviousMeetingsPopoverPanel meeting={meeting} />
        )}
      </Popover>
    </div>
  );
};

export default MeetingBreadcrumb;
